<script>
  import NewsMixing from "./NewsMixing";
  import { EventBus } from "@/assets/js/eventBus";
  export default {
    mixins: [NewsMixing],
    props: ["news"],
    components: {
      Comments: () => import("@/components/news/comments/Comments.vue"),
      Likes: () => import("@/components/likes/Likes.vue"),
      NewsViewBy: () => import("./NewsViewBy.vue"),
    },
    methods: {
      goViewNews: async function() {
        this.$store.state.news.NewsVisor = this.news;
        this.$router.push({ query: { news: this.news._id, comments: this.news.comments } }).catch(() => {});
        EventBus.$emit("scroll-to-comments");
      },
    },
  };
</script>
<template>
  <div :class="[$profile.role === 'superadmin' ? 'reactions' : 'reactions__users']">
    <Likes :likes="news.likes" :postId="news._id" nameEvent="likeNews" endpoint="/news/likes" :news="news" />
    <div class="newsList__comments">
      <div class="newsList__commentsBtn fcenter" @click="goViewNews">
        <iconic :class="['newsList__commentIco', news.newResponse ? 'newsList__commentIco--color' : '']" :name="news.comments ? 'commentContest' : 'comments'" />
        <p v-if="news.comments">{{ news.comments }}</p>
      </div>
    </div>
    <NewsViewBy :news="news" v-if="$profile.role === 'superadmin'" />
  </div>
</template>
<style lang="scss">
  .reactions {
    @include Flex(inherit, space-between, center);
    &__users {
      @include Flex(inherit, flex-start, center);
      height: 54px;
      padding: $mpadding;
    }
    .button {
      cursor: pointer;
      padding: $mpadding;
    }
    .likes.likes__contentRow,
    .newsList__comments {
      padding: $mpadding;
      margin: 0;
      .newsList__commentsBtn {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }
    }
  }
</style>
